import { template as template_982b8cdaa089483da6daa24158460130 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_982b8cdaa089483da6daa24158460130(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
