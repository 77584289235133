import { template as template_61037b1311024e25b8294d9b9747f019 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_61037b1311024e25b8294d9b9747f019(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
