import { template as template_4a7080d3bed8466fa96cb4d6eb3c9d24 } from "@ember/template-compiler";
const FKLabel = template_4a7080d3bed8466fa96cb4d6eb3c9d24(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
